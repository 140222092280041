<template>
  <v-card
    class="
      border-radius-xl
      card-shadow
      d-flex
      flex-column
      text-center
      justify-center
      h-100
    "
    elevation="2"
  >
    <div class="px-4 py-4 text-center">
      <h2
        class="text-gradient text-primary font-weight-bolder"
        style="font-size: 2rem"
      >
        <span>{{ formatNumber(number, 0) }}</span>
        <span class="text-lg"></span>
      </h2>
      <h6 class="mb-0 text-h6 font-weight-bolder text-typo">{{ title }}</h6>
      <p class="opacity-8 mb-0 text-sm text-typo">
        {{ content }}
      </p>
    </div>
  </v-card>
</template>

<script>
import { formatNumber } from "src/util/utils";
export default {
  name: "CardStatNumber",
  components: {},
  props: {
    number: {
      type: Number,
      default: 0,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formatNumber: formatNumber,
    };
  },
};
</script>
