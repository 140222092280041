<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col md="12">
          <v-card class="mb-6 card-shadow border-radius-xl py-4">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="2">
                  <v-menu
                    v-model="showStartPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    name="startDate"
                    :disabled="isLoading"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="startDate"
                        label="開始日期"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      color="blue-grey lighten-1"
                      @input="showStartPicker = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-menu
                    v-model="showEndPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    name="endDate"
                    :disabled="isLoading"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="endDate"
                        label="結束日期"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      color="blue-grey lighten-1"
                      @input="showEndPicker = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="2"
                  class="d-flex align-center"
                  :class="{
                    'pt-5': $vuetify.breakpoint.md || $vuetify.breakpoint.lg,
                  }"
                >
                  <div>
                    <v-btn
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-primary
                        bg-gradient-secondary
                        py-3
                        px-6
                        ms-3
                      "
                      @click="
                        pagination.page = 1;
                        getDonateStats();
                      "
                      :disabled="isLoading"
                      :loading="isLoading"
                    >
                      {{ $t('common["Search"]') }}</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="px-1 py-1">
            <v-card-text>
              <p class="text-h5 text--primary">捐贈發票狀態</p>
              <v-row align="center" fill-height>
                <v-col cols="12" md="3">
                  <CardStatNumber
                    :number="stats.totalInvoice"
                    title="捐贈成功總發票張數"
                  ></CardStatNumber>
                </v-col>
                <v-col cols="12" md="3">
                  <CardStatNumber
                    :number="stats.totalAmount"
                    title="發票總金額"
                  ></CardStatNumber>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              捐贈發票結果
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="fa-search"
                :label="$t(`common['Search']`)"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="tableHeaders"
                :items="tableData"
                :loading="isLoading"
                :loading-text="$t(`common['Loading... Please wait']`)"
                :no-data-text="$t(`common['No Data']`)"
                :search="search"
                :footer-props="{
                  'items-per-page-text': $t(`common['Rows per page']`),
                }"
                :page.sync="pagination.page"
                hide-default-footer
                @page-count="pagination.pageCount = $event"
                :items-per-page="pagination.itemsPerPage"
              >
              </v-data-table>
            </v-card-text>
            <v-card-actions class="card-padding">
              <Pagination
                :pagination="pagination"
                :total="tableData.length"
                :loading="isLoading"
                :show-items-per-page="false"
              ></Pagination>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import { checkPermission } from "src/util/utils";
import moment from "moment";
import { mapState } from "vuex";
import Pagination from "../Campaigns/Widgets/Pagination.vue";
import { fetchDonateInvoiceStats } from "src/apis/fetchData";
import CardStatNumber from "./Widgets/CardStatNumber.vue";

export default {
  name: "Dashboard",
  components: { Pagination, CardStatNumber },
  props: {},
  data() {
    return {
      moment: moment,
      isLoading: false,
      showStartPicker: false,
      showEndPicker: false,
      startDate: null,
      endDate: null,
      search: null,
      tableData: [],
      tableHeaders: [
        {
          text: "社福機關名稱",
          sortable: true,
          value: "socialWelfareName",
        },
        {
          text: "發票總金額",
          sortable: true,
          value: "totalAmount",
        },
        {
          text: "發票總張數",
          sortable: true,
          value: "totalInvoice",
        },
      ],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
      permissions: (state) => state.userPermissions,
    }),
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    stats() {
      let stats = {
        totalInvoice: 0,
        totalAmount: 0,
      };
      this.tableData.forEach((item) => {
        stats.totalInvoice += item.totalInvoice;
        stats.totalAmount += item.totalAmount;
      });
      return stats;
    },
  },
  created() {
    this.unwatchIsLoggedIn = this.$watch("isLoggedIn", (newVal) => {
      if (newVal) {
        this.unwatchIsLoggedIn();
        this.getDonateStats();
      }
    });
  },
  mounted() {
    this.startDate = moment().subtract(1, "year").format("YYYY-MM-DD");
    this.endDate = moment().format("YYYY-MM-DD");
    if (this.isLoggedIn) {
      this.getDonateStats();
    }
  },
  methods: {
    getDonateStats() {
      const start = moment(this.startDate);
      let end = moment(this.endDate);
      if (end.diff(start, "days") < 0) {
        this.$swal({
          // target: this.$refs.container,
          text: "搜尋區間最少為一天",
          type: "error",
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: this.$i18n.t(`common["Confirm"]`),
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
        });
        return;
      }
      end = moment(end.format("YYYY-MM-DD") + " 23:59:59");
      let query = {
        startTs: moment(start).unix(),
        endTs: moment(end).unix(),
      };
      this.isLoading = true;
      fetchDonateInvoiceStats(this.merchantId, query)
        .then((res) => {
          if (res && res.data && res.data.details) {
            this.tableData = [...res.data.details];
          }
        })
        .catch((e) => {
          console.error("failed to get donata invoice stats", e);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
